/* set animation for instructions */
.infoPanelItem {
  animation-name: example;
  animation-duration: 5s;
  transition: 3s;
}

@keyframes example {
  0% {
    color: rgb(164, 165, 152);
  }
  20% {
    color: rgb(146, 25, 55);
  }
  40% {
    color: rgb(180, 55, 86);
  }
  60% {
    color: rgb(146, 25, 55);
  }
  80% {
    color: rgb(180, 55, 86);
  }
}

.horizontalInfoPanel, .verticalInfoPanel {
  font-size: 14px;
  overflow: auto;
}

.horizontalInfoPanel {
  margin: 0 2rem;
}

.verticalInfoPanel {
  margin-top: 1rem;
}