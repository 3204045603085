.game {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Set the font size and font for text in model */
.modal {
  font-size: 16px;
  color: #777;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

.debugGrid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
	column-gap: 1rem;
}

.verticalGrid {
  width: min(80vw, 705px);
}

.horizontalGrid {
  display: flex;
  width: min(80vw, 1410px);
  justify-content: space-evenly;
}

.rightColumn {
	display: grid;
}

.horizontalPanels{
	display: flex;
	justify-content: center;
}

.verticalInfo {
	display: flex;
	justify-content: center;
}

.verticalOrientationToggle {
  margin-right: auto;
  margin-left: max(10vw, calc((100vw - 705px) / 2));
}

.horizontalOrientationToggle {
  margin-right: auto;
  margin-left: max(10vw, calc((100vw - 1410px) / 2));
}

.custom-cursor {
	cursor: url("/target_cursor.svg") 15 15, auto !important;
}

.scoreModal .ant-skeleton-avatar-circle {
	display: block !important;
	margin: 0.5rem auto;
}

.scoreModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.scoreModal Button {
	margin-left: auto;
}

.scoreModal h4 {
	text-transform: lowercase;
	font-family: "Roboto", sans-serif;
	font-variant: small-caps;
	font-weight: bold;
	font-size: 1.5em;
	letter-spacing: 0.05em;
	line-height: 0.8;
	color: #1890ff;
}

.scoreModalProgress {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-evenly;
	align-items: center;
}

.scoreModalProgress p {
	/* color: #1890ff; */
	line-height: 1;
	margin: 0;
	padding: 0;
}

.scoreModalProgress div {
	width: 60%;
	height: 2px;
	background-color: black;
	margin: 0.3rem 0;
}
